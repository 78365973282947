import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { RequestBwt } from "../../../../../models/request/request-bwt";
import { KeyValueObject } from "../../../../../models/core/KeyValueObject";
import { Subscription } from "rxjs";
import { ContragentsService } from "../../../../../../categories/contragents/contragents.service";
import { ContragentWithContactDto } from "../../../../../models/contragent/contragent-with-contact-dto";
import { DaDataAddressComponent } from "../../../../dadata-address/dadata-address.component";
import { AddressSuggestion } from "../../../../../models/dadata-address/address-suggestion";
import { RequestsService } from "../../../../../services/requests.service";
import { NgForm } from "@angular/forms";
import { CustomerObjectsService } from "../../../../../../categories/customer-objects/customer-objects.service";
import { CustomerObjectContactDto, CustomerObjectWithContactsDto } from "../../../../../models/customerObject/customer-object-with-contacts.dto";
import { CreateCustomerObjectComponent } from "../../../../customer-object/create-customer-object/create-customer-object.component";
import { DialogService } from "@progress/kendo-angular-dialog";
import { NotificationService } from "../../../../../../core/services/notification.service";

@Component({
	selector: 'request-page-customer-object-info',
	templateUrl: './request-page-customer-object-info.component.html',
	styleUrls: ['./request-page-customer-object-info.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RequestPageCustomerObjectInfoComponent implements OnInit {

	@ViewChild('entityForm') public entityForm: NgForm;
	@ViewChild("dadataAddressControl") dadataAddressControl: DaDataAddressComponent;

	@Input() request: RequestBwt = null;
	@Input() dataSaving: boolean = false;

	@Output() refreshRequestEvent: EventEmitter<RequestBwt> = new EventEmitter<RequestBwt>();

	customerObject: CustomerObjectWithContactsDto = new CustomerObjectWithContactsDto;
	savedCustomerObject: CustomerObjectWithContactsDto = new CustomerObjectWithContactsDto;

	contragentSearchPauseTime = 500;
	contragentsSearchDelay: NodeJS.Timer;
	contragentsSearchSubsription: Subscription;

	contragents: KeyValueObject[] = [];
	isEditing: boolean = false;
	loading: boolean;

	constructor(
		private customerObjectsService: CustomerObjectsService,
		private contragentsService: ContragentsService,
		private requestsService: RequestsService,
		private dialogService: DialogService,
		private notificationService: NotificationService
	) { }

	ngOnInit(): void {
		this.loading = true;
		if (this.customerObjectExist) {
			this.customerObjectsService.getCustomerObjectWithContacts(this.request.customerObjectId).subscribe(resp => {
				this.customerObject = { ...resp };
				if (this.dadataAddressControl) {
					this.dadataAddressControl.initValue(resp.address);
				}
				this.contragentsService.getContragentWithContact(this.customerObject.contragentId).subscribe(contragent => {
					this.setSelectedContragentName(contragent);
					this.loading = false;
				});
			});
		}
		else {
			this.customerObject = new CustomerObjectWithContactsDto();
		}
	}

    clientChange(value: string): void {
		let contragent = this.contragents.find(x => x.name == value);
		this.customerObject.contragentId = contragent != null ? contragent.id : null;

		if (this.customerObject.contragentId && this.customerObject.contragentId != 0) {
			this.contragentsService.getContragentWithContact(this.customerObject.contragentId).subscribe(resp => {
				this.setSelectedContragentName(resp);
			});
		}
	}

	clientFilterChange(value: string): void {
		if (!value || value.trim() == '' || value.trim().length < 3) {
			this.contragents = this.customerObject.contragentName
				? this.contragents.filter(x => x.id == this.customerObject.contragentId)
				: [];
		}
		else {
			this.searchContragents(value);
		}		
	}

	searchContragents(value: string) {
		if (this.contragentsSearchDelay) {
			clearTimeout(this.contragentsSearchDelay);
		}

		this.contragentsSearchDelay = setTimeout(() => {
			if (this.contragentsSearchSubsription) {
				this.contragentsSearchSubsription.unsubscribe();
			}

			this.contragentsSearchSubsription = this.contragentsService.searchByName(value)
            .subscribe((response) => {
                this.contragents = response.data;
			});
		}, this.contragentSearchPauseTime);
	}

	setSelectedContragentName(contragent: ContragentWithContactDto) {		
		this.customerObject.contragentName  = contragent.isIndividual
			? contragent.contactFullName
				? contragent.contactFullName
				: contragent.contactPhone
			: contragent.name
				? contragent.name
				: contragent.inn;
	}

	onAddressChange(value: AddressSuggestion) {
		this.customerObject.address = value.address;
	}

	save() {
		this.dataSaving = true;
		if (this.customerObject.contragentId != this.savedCustomerObject.contragentId) {
			this.notificationService.confirmation({
				title: 'Изменение клиента',
				message: `При изменении клиента объекта обслуживания будет изменен клиент заявки`,
				type: 'question',
				confirmButtonText: 'Сохранить',
				cancelButtonText: 'Отмена',
				showCloseButton: false,
			}, 
			() => this.updateCustomerObjectWithRequest(), 
			() => this.dataSaving = false );
		}
		else {
			this.updateCustomerObject();
		}
	}

	updateCustomerObject() {
		this.customerObjectsService.updateCustomerObjectWithContacts(this.customerObject).subscribe(() => {
			this.dataSaving = false;
			this.isEditing = false;
		}, () => { this.dataSaving = false; this.isEditing = false; });
	}

	updateCustomerObjectWithRequest() {
		this.customerObjectsService.updateCustomerObjectWithContacts(this.customerObject).subscribe(() => {
            this.updateRequest();
		}, () => { this.dataSaving = false; this.isEditing = false; });
	}

    openCustomerObjectDialog() {
        const dialogRef = this.dialogService.open({ content: CreateCustomerObjectComponent, width: '60%', height: '540px' });
		const createContragentForm = <CreateCustomerObjectComponent>dialogRef.content.instance;
		
		createContragentForm.selectedCustomerObject = this.customerObject;
        createContragentForm.contragentId = this.request.customerContragentId;
        createContragentForm.isEditing = false;
        createContragentForm.commitChanges = true;
		createContragentForm.onCancelEvent.subscribe(() => dialogRef.close());
		createContragentForm.onContinueEvent.subscribe((customerObject) => {
			dialogRef.close();
			this.dataSaving = true;
			this.customerObject = customerObject;
			this.updateRequest();
		});
    }

	updateRequest() {
		this.requestsService.getById(this.request.requestId).subscribe(resp => {
			resp.entity.customerObjectId = this.customerObject.customerObjectId;
			resp.entity.customerObjectName = this.customerObject.name;
			resp.entity.customerContragentId = this.customerObject.contragentId;
			resp.entity.customerContragentName = this.customerObject.contragentName;
			this.requestsService.update(resp.entity, null).subscribe(() => {
				this.requestsService.getBwtById(this.request.requestId).subscribe(updatedRequest => {
					this.request = updatedRequest.entity;
					this.refreshRequestEvent.emit(this.request);
					if (this.dadataAddressControl) {
						this.dadataAddressControl.initValue(this.customerObject.address);
					}
					this.contragentsService.getContragentWithContact(this.customerObject.contragentId).subscribe(contragent => {
						this.setSelectedContragentName(contragent);
						this.dataSaving = false;
					});
				});
			});
		});
	}

	turnOnEdit() {
		this.isEditing = true;
		this.savedCustomerObject = {...this.customerObject};
	}

	turnOffEdit() {
		this.isEditing = false;
		this.customerObject = {...this.savedCustomerObject};
		this.entityForm.resetForm(this.customerObject);
		if (this.dadataAddressControl) {
			this.dadataAddressControl.initValue(this.customerObject.address);
		}	
	}

	get customerObjectExist() {
		return this.request.customerObjectId && this.request.customerObjectId != 0;
	}

	get canSave() {
		return (!this.entityForm.pristine || this.addressHasChanged) && this.entityForm.valid;
	}

	get addressHasChanged() {
		return this.savedCustomerObject && this.savedCustomerObject.address != this.customerObject.address;
	}
}
