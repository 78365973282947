import { OnInit } from '@angular/core';
import { DataSourceRequestState, orderBy, FilterDescriptor, isCompositeFilterDescriptor, SortDescriptor, groupBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { ColumnBase, ColumnComponent, DataStateChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { DataService } from '../../../core/services/data.service';
import { ListViewModel } from '../../models/core/ListViewModel';
import { StatePersistingService } from '../../services/state-persisting.service';
import { GridSettings } from '../../models/grid/grid-settings';
import { AppService } from '../../../app.service';
import { UserFilter } from '../../models/user-filter/user-filter';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { PersistenceGridFeatures } from '../grid/persistence-grid-features/persistence-grid-features';
import { ColumnSettings } from '../../models/grid/column-settings';
import { saveAs } from 'file-saver';
import { ExportExcelFromGridService } from '../../services/export-excel-from-grid.service';

export abstract class ListPageBase<T> extends PersistenceGridFeatures implements OnInit {

	public loading: boolean = false;
	public listViewModel: ListViewModel<T> = new ListViewModel<T>();

	groupedData: any[] = [];
	groupedKey = '';

	abstract onListPageInit(): void;
	abstract processListItems(items: T[]): void;

	public enableSaveGridSettings: boolean = false;

	public gridColumnOffset: number = 0;

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'createdDate', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: []
	}

	public gridSettingsFilter: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'createdDate', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: []
	}

	gridSettingsStorageKey: string;
	gridSettingsFilterStorageKey: string;

	public selectedUserFilter: UserFilter = null;

	constructor(
		protected router: Router,
		protected appService: AppService,
		protected dataService: DataService<T, any>,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService
	) {
		super(appService, persistingService);
	}

	ngOnInit(): void {
   		super.ngOnInit();

		if (this.enableSaveGridSettings) {
			const gridSettings: GridSettings = this.persistingService.get(this.gridSettingsStorageKey);
			if (gridSettings !== null &&
				((!('clearFilter' in gridSettings) && ('clearFilter' in this.gridSettings)) || (gridSettings as any).clearFilter)) {
				this.setGridSettings(this.gridSettings);
				this.gridSettings['clearFilter'] = false;
				this.persistingService.set(this.gridSettingsStorageKey, this.gridSettings);
			} else {
				this.setGridSettings(gridSettings !== null ? gridSettings : this.gridSettings);
			}

			const gridSettingsFilter: GridSettings = this.persistingService.get(this.gridSettingsFilterStorageKey);
			if (gridSettingsFilter !== null &&
				((!('clearFilter' in gridSettingsFilter) && ('clearFilter' in this.gridSettingsFilter)) || (gridSettingsFilter as any).clearFilter)) {
				this.setGridSettings(this.gridSettingsFilter, true);
				this.gridSettingsFilter['clearFilter'] = false;
				this.persistingService.set(this.gridSettingsFilterStorageKey, this.gridSettingsFilter);
			} else {
				this.setGridSettings(gridSettingsFilter !== null ? gridSettingsFilter : this.gridSettingsFilter, true);
			}
		}

		this.onListPageInit();
		this.loadData();
	}

	public loadGridData(): void {
		this.loadData();
	}

	public loadData(state: DataSourceRequestState = this.gridSettings.state): void {
		this.loading = true;

		this.dataService.list(state).subscribe(lvm => {
			this.processListItems(lvm.data);
			this.listViewModel = lvm;
			this.groupedData = this.groupedByKey;
			this.loading = false;
		});
	}

	protected onGridStateChange(isFilter: boolean = false): void {

	}

	public dataStateChange(state: DataStateChangeEvent): void {
		this.gridSettings.state = state;
		this.loadData();

		this.onGridStateChange();

		this.saveGridSettings();
	}

	public onReorder(e: any, isFilter: boolean = false): void {

		if (e.oldIndex === e.newIndex) {
			return;
		}

		let gs = this.gridSettings;

		if (isFilter) {
			gs = this.gridSettingsFilter;
		}

		const oldIndex = gs.columnsConfig.findIndex(col => col.field === e.column.field);
		const newIndex = e.newIndex - this.gridColumnOffset;

		const columnSetting = gs.columnsConfig[oldIndex];
		gs.columnsConfig.splice(oldIndex, 1);
		gs.columnsConfig.splice(newIndex, 0, columnSetting);
		gs.columnsConfig.forEach((col, i) => {
			col.orderIndex = i;
		});

		const json = JSON.stringify(gs.columnsConfig);

		if (isFilter) {
			this.gridSettingsFilter.columnsConfig = JSON.parse(json);
		} else {
			this.gridSettings.columnsConfig = JSON.parse(json);
		}


		this.onGridStateChange(isFilter);

		this.saveGridSettings(isFilter);
	}

	public onResize(e: any, isFilter: boolean = false): void {

		let gs = this.gridSettings;

		if (isFilter) {
			gs = this.gridSettingsFilter;
		}

		e.forEach(item => {
			gs.columnsConfig.find(col => col.field === item.column.field).width = item.newWidth;
		});

		this.onGridStateChange(isFilter);

		this.saveGridSettings(isFilter);
	}

	public onVisibilityChange(e: any, isFilter: boolean = false): void {

		let gs = this.gridSettings;

		if (isFilter) {
			gs = this.gridSettingsFilter;
		}

		e.columns.forEach(column => {
			gs.columnsConfig.find(col => col.field === column.field).hidden = column.hidden;
		});

		var i = 0;
		const columnsConfig = orderBy(gs.columnsConfig, [{ field: "hidden", dir: "asc" }, { field: "orderIndex", dir: "asc" }]);
		columnsConfig.forEach(col => {
			col.orderIndex = i++;
		});
		const json = JSON.stringify(columnsConfig);

		if (isFilter) {
			this.gridSettingsFilter.columnsConfig = JSON.parse(json);
		} else {
			this.gridSettings.columnsConfig = JSON.parse(json);
		}

		this.onGridStateChange(isFilter);

		this.saveGridSettings(isFilter);
	}

	public mapGridSettings(gridSettings: GridSettings): GridSettings {
		const state = gridSettings.state;
		this.mapDateFilter(state.filter);

		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex),
			...gridSettings,
		};
	}

	protected saveGridSettings(isFilter: boolean = false): void {
		if (!this.enableSaveGridSettings) {
			return;
		}

		let gs = this.gridSettings;
		let sk = this.gridSettingsStorageKey;

		if (isFilter) {
			gs = this.gridSettingsFilter;
			sk = this.gridSettingsFilterStorageKey;
		}

		const gridConfig = {
			columnsConfig: gs.columnsConfig,
			state: gs.state,
			...gs,
		};

		this.persistingService.set(sk, gridConfig);
	}

	get clearFiltersButtonVisibility(): boolean {
		return this.gridSettings.state.filter.filters &&
			this.gridSettings.state.filter.filters.length > 0;
	}

	clearFilters(): void {

		this.gridSettings.state = {
			skip: 0,
			take: 100,
			sort: [{ field: 'createdDate', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		};

		this.saveGridSettings();

		this.appService.clearAllGridFilters.next();

		this.onGridStateChange();

		this.loadData();
	}

	public get groupedByKey() {

		if(!this.groupedKey) {
			return [];
		}

		return groupBy(this.listViewModel.data, [{field: this.groupedKey, }]);
	}

	public get gridFilterHeight(): number {
		return 130;
	}

	public get gridHeight(): number {
		console.log(window.innerHeight);
		return window.innerHeight - 245;
	}

	public allData = (): Observable<ListViewModel<T>> => {
		this.loading = true;
		var customState: DataSourceRequestState = {
			skip: 0,
			take: this.listViewModel.total,
			sort: this.gridSettings.state.sort,
			filter: this.gridSettings.state.filter
		};

		return this.dataService.list(customState)
			.pipe(tap(x => {
				this.processListItems(x.data);
				return x.data;
			}))
			.pipe(finalize(() => this.loading = false));
	};

	protected actualizeHiddenColumnsFilteringAndSorting(columns: ColumnBase[]): void {
		var fieldNames = columns.filter(column => column.hidden).map((col: ColumnComponent) => ({
			field: col.field,
			filterField: this.gridSettings.columnsConfig.find(cs => cs.field === col.field).filterField
		}));

		if (fieldNames.length > 0) {
			this.gridSettings.state.sort = this.gridSettings.state.sort.filter(sd =>
				!fieldNames.map(fn => fn.field).includes(sd.field));

			this.gridSettings.state.filter = this.removeHiddenColumnsFilters(this.gridSettings.state.filter, fieldNames.map(
				fn => fn.filterField ? fn.filterField : fn.field));

		}
	}

	private removeHiddenColumnsFilters(filters: CompositeFilterDescriptor, filterFieldNames: Array<string>): CompositeFilterDescriptor {
		filters.filters.filter(fd => !("field" in fd)).forEach((cfd: CompositeFilterDescriptor) => {
			cfd = this.removeHiddenColumnsFilters(cfd, filterFieldNames);
		});
		filters.filters = filters.filters.filter(fd =>
			("field" in fd
				&& typeof (fd as FilterDescriptor).field === "string"
				&& !filterFieldNames.includes((fd as FilterDescriptor).field.toString()))
			|| (!("field" in fd)
				&& (fd as CompositeFilterDescriptor).filters.length > 0
			)
		);
		return filters;
	}

	protected getXlsx(entitiesName: string,
		fileName: string,
		columnsConfigCopyAction: (columnsConfigCopy: ColumnSettings[]) => void,
		callback: () => void,
		fillToType : boolean = false) {
		let columnsConfigCopy: ColumnSettings[] = [];
		this.gridSettings.columnsConfig.forEach(f => {

			let columnSetting: any = new Object();
			columnSetting.field = f.field;
			columnSetting.title = f.title;
			columnSetting.filter = f.filter;
			columnSetting.format = f.format;
			columnSetting.width = f.width;
			columnSetting._width = f._width;
			columnSetting.filterable = f.filterable;
			columnSetting.orderIndex = f.orderIndex;
			columnSetting.hidden = f.hidden;
			columnSetting.excelType = f.excelType;

			columnsConfigCopy.push(columnSetting);
		});

		if (columnsConfigCopy && columnsConfigCopy.length > 0) {
			columnsConfigCopyAction(columnsConfigCopy);
		}

		this.exportExcelFromGridService.getGridReport(entitiesName, this.listViewModel.total, this.gridSettings.state, columnsConfigCopy, fillToType).subscribe(blob => {
			saveAs(blob, fileName, { type: 'application/octet-stream' });
			callback();
		});
	}

	protected splitBr(str: string): string[] {
		let res = [str];

		if (str.includes("\r\n")) {
			res = str.split("\r\n");
		}

		if (str.includes("\n")) {
			res = str.split("\n");
		}

		return res;
	}
}
